import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["scrollable"]

  connect() {}

  scrollLeft() {
    this.scrollableTarget.scrollLeft -= 1000;
  }

  scrollRight() {
    this.scrollableTarget.scrollLeft += 1000;
  }
}