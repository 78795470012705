import FinderFieldController from "./finder_field_controller";

export default class extends FinderFieldController {
  static targets = ['type', 'subtype', "amenitiesField", "amenitiesChecks", "saleCheck", "rentCheck", "salePrice", "rentPrice", "updateForm", "activeCheck", "demand"];

  connect() { 
    addEventListener("submit", (event) => {
      setTimeout(() => {
        this.updateFormTarget.checked = false;
      }, 200);
    });
  }


  forceUpdateAll() {
    setTimeout(() => {			
      this.updateFormTarget.checked = true;
			this.submitTarget.click();
		},500);
	}
  

  types(){

		let type = this.typeTarget
		let allSubtypes = this.subtypeTarget
		let subtypes =  this.formTarget.getElementsByClassName('select_' + type.value)

		for(var i = 1; i<allSubtypes.options.length; i++){
				if(!allSubtypes.options[i].classList.contains("hidden")) allSubtypes.options[i].classList.add("hidden")
		}

		for (var i=0; i<subtypes.length; i++) {
				subtypes[i].classList.remove("hidden");
		}

		if(type.value == ""){
			allSubtypes.options[0].classList.remove('hidden')
			allSubtypes.value = allSubtypes.options[0].value
		} else {
			allSubtypes.options[0].classList.add('hidden')
			allSubtypes.value = subtypes[0].value
		}
			 
	}


  setFinder() {
		let type = this.typeTarget.value
		let subtype = this.subtypeTarget.value
    let allFields = this.formTarget.getElementsByClassName("typeField")
		let allInputs
		let currentInput

    this.amenitiesFieldTarget.value = ""
    this.updateFormTarget.checked = true
    //this.formTarget.reset()

		//Go through the form fields to show the correct ones and reset them
		for(var i = 0; i<allFields.length; i++){
      allFields[i].classList.add('hidden')
      allInputs = allFields[i].querySelectorAll('input,select')
      allInputs.forEach((input) => {
        if (input.tagName === 'SELECT') {
          input.selectedIndex = 0;
        } else if (input.getAttribute("type") == "checkbox") {
          input.checked = false;
        } else {
          input.value = null;
        }
      })
			if(type == "" || allFields[i].classList.contains(type) || allFields[i].classList.contains(subtype))	allFields[i].classList.remove("hidden")
		}
  }
  

  amenities() {
    let checkboxes = this.amenitiesChecksTarget.querySelectorAll("input");
    let amenities = "";
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) amenities += `${checkbox.value}-`;
    });
    if (amenities != "") amenities = amenities.slice(0, -1);
    this.amenitiesFieldTarget.value = amenities;
    this.forceUpdate();
  }

  onSale(event) {
    this.rentCheckTarget.checked = false;
    if (event.target.checked) {
      this.resetSelect(this.rentPriceTarget);
      this.salePriceTarget.classList.remove("hidden");
    } else {
      this.resetSelect(this.salePriceTarget);
    }
    this.forceUpdate();
  }

  onRent(event) {
    this.saleCheckTarget.checked = false;
    if (event.target.checked) {
      this.resetSelect(this.salePriceTarget)
      this.rentPriceTarget.classList.remove("hidden");
    } else {
      this.resetSelect(this.rentPriceTarget);
    }
    this.forceUpdate();
  }

  resetSelect(select) {
    select.classList.add("hidden")
    select.querySelector("select").selectedIndex = 0
  }

  activeProperties() {
    let selector = document.getElementById("release_status_selector");
    this.activeCheckTarget.checked = true
    selector.selectedIndex = 4;
    selector.setAttribute("disabled", "disabled");
    selector.classList.add("disabled")
    this.forceUpdate()
  }

  allProperties() {
    let selector = document.getElementById("release_status_selector");
    this.activeCheckTarget.checked = false;
    selector.selectedIndex = 0;
    selector.removeAttribute("disabled");
    selector.classList.remove("disabled");
  }

  createDemand() {
    this.demandTarget.click()
    this.forceUpdate()
  }
}
