import { Controller } from "stimulus"
import { MarkerClusterer } from "@googlemaps/markerclusterer";

let markers
let map
let icon
let icon2
let infoWindow;
let geocoder

export default class extends Controller {

  static targets = ["map", "noResult"]
  static values = { icon: String, icon2: String, properties: String }

  connect() {
    markers = []
    infoWindow = new google.maps.InfoWindow();
    geocoder = new google.maps.Geocoder();
    icon = {
      url: this.iconValue,
      scaledSize: new google.maps.Size(48, 48),
    };
    icon2 = {
      url: this.icon2Value,
      size: new google.maps.Size(80, 80),
      scaledSize: new google.maps.Size(80, 80),
      anchor: new google.maps.Point(40, 40)
    };
    this.setMap()
  }

  setMap() {
    const lat = sessionStorage.getItem("lat") ? sessionStorage.getItem("lat") : 40.417
    const lng = sessionStorage.getItem("lng") ? sessionStorage.getItem("lng") : -3.703
    map = new google.maps.Map(this.mapTarget, {
      center: { lat: parseFloat(lat), lng: parseFloat(lng) },
      zoom: 12,
    });
    this.setMarkers(map);
    window.initMap = this.setMap;
    setTimeout(() => {
      this.setCenter()
    }, 5000);
  }

  setMarkers() {
    let url = `/finder/markers?properties=${this.propertiesValue}`;
    
    if (this.propertiesValue) {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            let pos = {
              lat: parseFloat(data[i].property.latitude),
              lng: parseFloat(data[i].property.longitude),
            };
            const exact_address = data[i].property.show
            let marker = new google.maps.Marker({
              position: pos,
              map: map,
              icon: exact_address ? icon : icon2,
              opacity: exact_address ? 1 : 0.7
            });
            markers.push(marker);

            this.setInfoWindow(marker, data, i)

          }
          let cluster = new MarkerClusterer({ map, markers });
        });
    } else {
      this.noResultTarget.classList.remove('hidden')
    }
  }

  setInfoWindow(marker, data, i) {
    let area = data[i].property.parcel != undefined && data[i].property.parcel !== 0 ? data[i].property.parcel : data[i].property.builded;
    let rent_price = data[i].property.rent_price == '0 €' ? "" : `<p class="font-bold">${data[i].property.rent_price}/mes</p>`
    let sale_price = data[i].property.sale_price == '0 €' ? "" : `<p class="font-bold">${data[i].property.sale_price}</p>`
    let bedrooms = data[i].property.bedrooms == undefined ? "" : `<p>${data[i].property.bedrooms} habs.</p>`
    let disponibility
    if (sale_price == "") {
      disponibility = "Alquiler"
    } else if (rent_price == "") {
      disponibility = "Compra"
    } else {
      disponibility = "Compra y alquiler"
    }

    let locale = document.documentElement.lang;
    let title = data[i].property.title.replace(/\s+(?=\S)/g, '-');
    title = title.replace(/\.$/, '');
    let url = encodeURI(`/${locale}/property/${data[i].property.id}/${title}`)
    let infoWindowContent =
      '<div class="w-full sm:w-[360px] grid grid-cols-2 gap-5 px-2 pb-4">' +
      '<div class="relative">' +
      `<a href="${url}" class="pb-1">` +
      `<img class="w-full h-[120px] object-contain" src="${data[i].property.image}" alt="${data[i].property.title}" >` +
      "</a>" +
      "<div class='absolute bg-bee-brown text-white bottom-0 sm:left-2 px-2 sm:px-4 py-1'>" +
      rent_price +
      sale_price +
      "</div> " +
      "</div> " +
      "<div class='flex flex-col gap-2'>" +
      `<p class="text-bee-brown font-semibold">${data[i].property.subtype}</p>` +
      `<p>${data[i].property.municipality}</p>` +
      '<div class="flex gap-5 border-b border-gray-200 pb-2">' +
      bedrooms +
      `<p>${area} m²</p>` +
      "</div>" +
      `<p>Disponibilidad: ${disponibility}</p>` +
      "</div>" +
      "</div>";
    
    google.maps.event.addListener(marker, "click", function () {
      infoWindow.setContent(infoWindowContent);
      infoWindow.open(map, marker);
    });
  }

  setCenter() {
    let community = document.getElementById("community_selector_min") || document.getElementById("community_selector");
    let province = document.getElementById("province_selector")
    if (province) {
      const place = `${community.value}, ${province.value}, España`
      geocoder.geocode({ address: place }, function(results, status) {
        if (status === 'OK') {
          map.setCenter(results[0].geometry.location);
          map.setZoom(12);
          sessionStorage.setItem('lat', results[0].geometry.location.lat());
          sessionStorage.setItem('lng', results[0].geometry.location.lng());
        } else {
          alert('La geocodificación inversa ha fallado debido a: ' + status);
        }
      });
    }
  }

}