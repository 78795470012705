import { Controller } from "stimulus";
import { turbo_fetch, getURLParam, multipleToggle } from "helpers";

export default class extends Controller {
  static targets = [
    "priceButton",
    "keysButton",
    "visitsButton",
    "observationsButton",
    "profileButton",
    "releasesButton",
    "addEvent",
    "addEventButton",
    "modal",
    "keyComment",
    "posterComment",
  ];
  static values = { property: String, model: String, commercial: Boolean };

  connect() {
    setTimeout(() => {
      switch (getURLParam("annotation")) {
        case "price":
          this.priceButtonTarget.click();
          if (getURLParam("add") == "true") {
            let interval = setInterval(() => {
              if (this.hasAddEventButtonTarget) {
                clearInterval(interval);
                this.addEventButtonTarget.click();
              }
            }, 100);
          }
          break;
        case "keys":
          this.keysButtonTarget.click();
          break;
        case "visits":
          this.visitsButtonTarget.click();
          break;
        case "observations":
          this.observationsButtonTarget.click();
          break;
        case "releases":
          this.releasesButtonTarget.click();
          break;
        case "profile":
          this.profileButtonTarget.click();
          break;
        default:
          if (this.commercialValue) this.keysButtonTarget.click();
          break;
      }
    }, 100);
  }

  popUp() {
    let modelClasses = this.modalTarget.classList;
    if (modelClasses.contains("opacity-0")) {
      modelClasses.remove("hidden");
      setTimeout(function () {
        modelClasses.remove("opacity-0");
      }, 1);
    } else {
      modelClasses.add("opacity-0");
      setTimeout(function () {
        modelClasses.add("hidden");
      }, 500);
    }
  }

  closeForm() {
    setTimeout(() => {
      if (document.getElementsByClassName("field_with_errors").length <= 0) {
        this.popUp();
      }
    }, 500);
  }

  add(event) {
    let annotation = event.target.dataset.annotation;
    let url = `/app/${this.modelValue}/${this.propertyValue}/${annotation}/new`;
    turbo_fetch(url);
  }

  edit(event) {
    let annotation = event.target.dataset.annotation;
    let element = event.target.dataset.element;
    let url = `/app/${this.modelValue}/${this.propertyValue}/${annotation}/${element}/edit`;
    turbo_fetch(url);
  }

  show(event) {
    let annotation = event.target.dataset.annotation;
    let element = event.target.dataset.element;
    let url = `/app/${this.modelValue}/${this.propertyValue}/${annotation}/${element}`;
    turbo_fetch(url);
  }

  release() {
    turbo_fetch(`/app/${this.propertyValue}/estates`);
  }

  checkKey(event) {
    const e = event.target;
    if (!e.checked) {
      this.keyCommentTarget.value = "";
      this.keyCommentTarget.setAttribute("readonly", true);
    } else {
      this.keyCommentTarget.removeAttribute("readonly");
    }
  }

  checkPoster(event) {
    const e = event.target;
    if (!e.checked) {
      this.posterCommentTarget.value = "";
      this.posterCommentTarget.setAttribute("readonly", true);
    } else {
      this.posterCommentTarget.removeAttribute("readonly");
    }
  }
}
