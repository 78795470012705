import { Controller } from 'stimulus';
import "ace-builds/src-min-noconflict/ace.js";

/*
 * Avatar Edition controller
 * Let to create a dropzone zone to
 * upload and preview files.
 *
 */

export default class extends Controller {

	static targets = [ 'hiddenContentHTML', 'modalPreview', 'modalPreviewContent' ];

	connect () {
    
    //
    // Loading ACE editor and initializing
    //

    ace.config.set('basePath', '/js');
    ace.config.set('modePath', '/js');
    ace.config.set('themePath', '/js');
    ace.config.set('workerPath','/js');

    let editor = ace.edit('editor');

    editor.session.setMode('ace/mode/html');
    editor.setTheme('ace/theme/nord_dark');
    editor.session.setUseWorker('ace/worker/html');

    editor.session.on('change', (delta) => {
      this.hiddenContentHTMLTarget.value = editor.getValue();
    });

    document.getElementById('editor').style.fontSize = '15px';
  }

  openPreview () {

    let content_html = document.getElementById('page_content_html').value;

    this.modalPreviewContentTarget.innerHTML = content_html;
    this.modalPreviewTarget.classList.remove('hidden');

  }

  closePreview() {
    this.modalPreviewTarget.classList.add('hidden');
  }

}