import { Controller } from "stimulus";

const alert_message = "Leave site? Changes you made may not be saved."

export default class extends Controller {

  connect() { }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(alert_message)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = alert_message;
        return event.returnValue;
      }
    }
  }

  allowFormSubmission() {
    this.setChanged("false")
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  isFormChanged() {
    return this.data.get("changed") == "true";
  }

}
