import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ 'image' ];

  connect() {
  }

  removeImages(event){
    let imageId = event.currentTarget.dataset['id'];

    this.imageTarget.remove();

    let imageContainer = document.getElementById('images_container');

    let input = document.createElement('input');

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'ingredient[remove_images][id]'.replace('id', imageId));
    input.setAttribute('value', true);
    input.setAttribute('id', 'ingredient_remove_images_id_' + imageId);

    imageContainer.append(input);
  }
  
}