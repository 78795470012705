import { Controller } from "stimulus"
import { hideSelect, getURLParam } from "helpers";

export default class extends Controller {

  static targets = [
    "submit", 
    "saleRent", 
    "saleSelector", 
    "rentSelector", 
    "type",
    "subtypes",
    "amenitiesField",
    "amenitiesChecks",
    "form",
    "hiddenForm",
    "penthouse",
    "duplex",
    "independent",
    "interior",
    "study",
    "status",
    "furniture",
    "air",
    "heating",
    "energy",
    "emissions",
    "lastfloor",
    "building",
    "roadaccess",
    "disabledaccess",
    "pets",
    "updateForm",
    "bedroom",
    "bathroom",
    "larea",
    "parea",
    "rent",
    "sale", 
    "demand"
  ]

  connect() {
    window.scrollTo(0, 0);
  }

  updateAll() {
    this.updateFormTarget.checked = true
    this.update()
  }

  update() {
    setTimeout(() => {
      this.submitTarget.click();
      this.updateFormTarget.checked = false;
		},500);
	}

  select(event) {
    let e = event.target
    let previous = document.getElementsByClassName("selected")[0]
    previous.classList.remove("selected", "border-black")
    previous.classList.add("border-transparent")
    e.classList.add("selected", "border-black")
    e.classList.remove("border-transparent")

    this.saleRentTarget.value = e.innerText;
    this.setPrices()
  }

  setPrices() {
    if (this.hasSaleSelectorTarget && this.hasRentSelectorTarget) {
      if (this.saleRentTarget.value == "Alquilar") {
        hideSelect(this.saleSelectorTarget);
        this.rentSelectorTarget.classList.remove("hidden");
      } else {
        hideSelect(this.rentSelectorTarget);
        this.saleSelectorTarget.classList.remove("hidden");
      }
    } 
  }

  fillTarget(event) {
    const target = this[`${event.target.dataset.target}Target`]
    if (target.type && target.type === "checkbox") {
      target.click()
    } else {
      target.value = event.target.value
    }
  }

  resetFinder() {
    const inputs = this.hiddenFormTarget.querySelectorAll('input')
    inputs.forEach((input) => { 
      if (input.type && input.type === "checkbox") { 
        input.checked = false
      } else {
        input.value = ""
      }
    })
  }

  amenities() {
    let checkboxes = this.amenitiesChecksTarget.querySelectorAll("input");
    let amenities = "";
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) amenities += `${checkbox.value}-`;
    });
    if (amenities != "") amenities = amenities.slice(0, -1);
    this.amenitiesFieldTarget.value = amenities;
    this.update();
  }

  generateDemand() {
    this.demandTarget.checked = true
    this.update()
  }

  types() {
    let type = this.typeTarget;
    let subtype = this.subtypeTarget;
    let options = this.formTarget.getElementsByClassName("select_" + type.value);

    for (var i = 1; i < subtype.options.length; i++) {
      subtype.options[i].classList.add("hidden");
    }

    subtype.value = options[0].value;
    for (var i = 0; i < options.length; i++) {
      options[i].classList.remove("hidden");
    }

  }

}