import { isBrowserSupported } from 'dropzone';
import { Controller } from 'stimulus';
import { multipleToggle, getElementPath, getDataValue } from "helpers";

/*
 * Admin Finder Field controller
 * Let users to search entities in dashboard.
 *
 */

export default class extends Controller {

	static targets = [
		'form',
		'input',
		'submit',
		'sortField',
		'sortDirection',
		'tableHeader'
	];

	// To avoid concanated searches 
	lastSearchValue = null;


	connect () {
    
		//
		// When Turbo request starts
		//

		this.formTarget.addEventListener('turbo:submit-start', () => {
			this.submitTarget.disabled = true;
			this.submitTarget.innerHTML = `
				<svg class='animate-spin h-5 w-5 text-blue-500' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
	        <circle class='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' stroke-width='4'></circle>
	        <path class='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
	      </svg>
			`;
		});


		//
		// When Turbo request finishes
		//

		this.formTarget.addEventListener('turbo:submit-end', () => {
			setTimeout(() => {
				this.submitTarget.disabled = false;
				this.submitTarget.innerHTML = 'Search';
			}, 500);
		});
	}


	/*
	 * update
	 * Send string to backend on user's typing
	 *
	 */

  update(event) {
    
		if ( this.inputTarget.value.length > 3 || this.inputTarget.value.length === 0 ) {
			setTimeout(() => {				
				if ( this.lastSearchValue === this.inputTarget.value )
					return;
				else {
					this.lastSearchValue = this.inputTarget.value;
					this.submitTarget.click();
				}
			}, 700);
		}

	}

	forceUpdate() {
		setTimeout(() => {				
			this.submitTarget.click();
		},500);
	}


	sort(event) {
		var path = event.path || (event.composedPath && event.composedPath());
    let field = getDataValue(path, "field");
		if(this.sortFieldTarget.value != "" && this.sortFieldTarget.value != field ) this.sortDirectionTarget.value = ""
    this.sortFieldTarget.value = field
		this.forceUpdate()

		let svg = getElementPath(path, "tag", "th").querySelector("svg");
		let previousSvg = this.tableHeaderTarget.querySelector(".text-red-500");
    if (svg) {
      svg.classList.toggle("-rotate-180");
      svg.classList.add("text-red-500");
		}
		
		if (previousSvg && previousSvg != svg) {
			previousSvg.classList.remove("text-red-500", "-rotate-180");
			multipleToggle(svg, "h-4", "h-5");
      multipleToggle(svg, "w-4", "w-5");
      multipleToggle(previousSvg, "h-4", "h-5");
      multipleToggle(previousSvg, "w-4", "w-5");
    }  

		if(this.sortDirectionTarget.value == "desc"){
			this.sortDirectionTarget.value = "asc"
		} else {
			this.sortDirectionTarget.value = "desc"
		}
    //turbo_fetch(new_url)
  }


	/*
	*
	* Update subtype selector, depends on the type selected
	* Just for properties finder
	*
	*/

	//scale-0 scale-100
	dropdown(event) {
		let section = event.target
		let reset
		if(section.tagName == "INPUT"){
			reset = true
		} else {
			reset = false
		}

		while(!section.classList.contains("menu-section")){
			section = section.parentNode
		}
		let dropdown = section.childNodes[3]
		let svg = section.querySelector('svg')
		if(svg != null) svg.classList.toggle('-rotate-180')
		multipleToggle(dropdown, 'scale-0', 'scale-100')
		dropdown.children[0].classList.toggle('hidden')

		if(reset && dropdown.classList.contains('scale-0')){
			dropdown.querySelectorAll('input').forEach( function (input) {
				input.value = ""
			})
		}
	}

}