import { Controller } from "stimulus";
import { turbo_fetch, multipleToggle } from 'helpers';

export default class extends Controller {

    static targets = ["field", "owner", "id", "name", "email", "phone", "dropdown", "dropdownTooltip"];
    static values = {action: String, model: String, familyContact: String, name: String}

    connect() {
        document.addEventListener("autocomplete.change", this.autocomplete.bind(this))
        let input = this.fieldTarget
        if (input.dataset.company != undefined) {
            input.value = input.dataset.company
        }

        // Set contact id when it comes from a family
        if (this.hasFamilyContactValue) {
            this.idTarget.value = this.FamilycontactValue;
            this.blockField()
        }

        // Set contact info if it is created
        if (this.idTarget.value != "") {
            if (this.hasNameValue) {
                this.fieldTarget.value = this.nameValue;
            } else {
                this.setOwner();
                let interval = setInterval(() => {
                    if (this.hasFieldTarget && this.nameTarget.value != "") {
                        clearInterval(interval)
                        this.fieldTarget.value = this.nameTarget.value
                    }
                }, 100)
            }
        } 
    }

    autocomplete() {
        let comp = this.fieldTarget.value
        let dir = `/admin/clients/selector?company=${comp}`
        turbo_fetch(dir)
    }

    validateForm() {
        let x = document.getElementById('company_exists')
        let form = document.getElementById(`admin_${this.actionValue}_property_form`)
        if (x != null && x != undefined){
            this.fieldTarget.classList.add("error")
            x.classList.add("text-red-600")
            this.fieldTarget.focus()
            return
        } else {
            this.fieldTarget.classList.remove("error")
            document.getElementById(`submit_${this.actionValue}_property`).click()
        }
    }

    dropdown(){
        let dropdown = this.dropdownTarget.children[1]
        this.dropdownTarget.querySelectorAll("svg")[1].classList.toggle("-rotate-180");
        dropdown.children[0].classList.toggle('hidden')
        multipleToggle(dropdown, 'scale-0', 'scale-100')
    }

    fillDropdown() {
        setTimeout(() => {
            if (this.nameTarget.value == "" || this.emailTarget.value == "" || this.phoneTarget.value == "") {
                this.dropdownTooltipTarget.classList.remove("hidden");
            } else {
                this.dropdownTooltipTarget.classList.add("hidden");
            }
            if (document.getElementsByClassName("field_with_errors").length > 0) document.getElementsByClassName("field_with_errors")[0].remove()
        }, 500);
        
    }

    setOwner(){
        let owner = this.idTarget.value
        fetch(`/app/${this.modelValue}/get/${owner}`)
        .then(response => response.json())
        .then((data) => {
            this.nameTarget.value = data.name
            this.emailTarget.value = data.mail
            this.phoneTarget.value = data.phone
            this.blockDropdown()
        })
    }

    resetOwner(){
        this.nameTarget.value = this.fieldTarget.value
        this.emailTarget.value = ""
        this.phoneTarget.value = ""
        this.unblockDropdown()
    }
    
    removeOwner() {
        this.fieldTarget.value = this.nameTarget.value;
        this.idTarget.value = ""
        this.unblockDropdown();
    }

    blockField() {
        this.fieldTarget.disabled = true
        this.fieldTarget.classList.add("disabled")
        this.blockDropdown()
    }

    blockDropdown() {
        if (this.hasNameTarget) {
          this.nameTarget.disabled = true;
          this.nameTarget.classList.add("disabled");
        }
        if (this.hasEmailTarget) {
          this.emailTarget.disabled = true;
          this.emailTarget.classList.add("disabled");
        }
        if (this.hasPhoneTarget) {
          this.phoneTarget.disabled = true;
          this.phoneTarget.classList.add("disabled");
        }
    }

    unblockDropdown() {
        if (this.hasNameTarget) {
          this.nameTarget.removeAttribute("disabled");
          this.nameTarget.classList.remove("disabled");
        }
        if (this.hasEmailTarget) {
          this.emailTarget.removeAttribute("disabled");
          this.emailTarget.classList.remove("disabled");
        }
        if (this.hasPhoneTarget) {
          this.phoneTarget.removeAttribute("disabled");
          this.phoneTarget.classList.remove("disabled");
        }
    }

}