import { Controller } from "stimulus";
import { turbo_fetch, getURLParam, multipleToggle } from 'helpers';

export default class extends Controller {

  static targets = ["modal", "form"]
  static values = { url: String }

  connect() {}

  popUp() {
    let modal = this.modalTarget.classList;
    if (modal.contains("opacity-0")){
      modal.remove("hidden")
      setTimeout( function() {
        modal.remove("opacity-0")
      }, 1)
    } else {
      modal.add("opacity-0")
      setTimeout( function() {
        modal.add("hidden")
      }, 500)
    }
  }

  closeForm() {
    setTimeout(() => {
      if (document.getElementsByClassName("field_with_errors").length <= 0) {
        this.popUp();
      }
    }, 500);
  }

}