import { Controller } from "stimulus";
import { getElementPath } from "helpers";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.timeout = null;
    const textAreas = this.element.querySelectorAll("textarea");
    textAreas.forEach((input) => {
      input.addEventListener("input", this.enableTranslation.bind(this));
    });
  }

  async translate(event) {
    document.getElementById("translate-spinner").classList.remove("hidden");
    event.preventDefault();
    this.disableTranslation()
    const langFrom = event.target.dataset.lang;
    const langs = ["es", "en", "de", "fr", "it", "ru", "nl", "sv", "da", "fi"].filter((e) => e !== langFrom);
    let section;
    let checkTranslate;
    let textareas;

    section = document.getElementById(`section-${langFrom}`);
    textareas = section.getElementsByTagName("textarea");
    const titleFrom = textareas[0].value;
    const descriptionFrom = textareas[1].value;

    let url = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCYfky24mXROOB8wY3VTMLJJVzBQdHtofE&source=${langFrom}`;

    for (const lang of langs) {
      section = document.getElementById(`section-${lang}`);
      if (section) {
        checkTranslate = document.getElementById(`check-${lang}`).checked;
        if (checkTranslate) {
          textareas = section.getElementsByTagName("textarea");
          textareas[0].value = await translateText(url, titleFrom, lang);
          textareas[0].removeAttribute("maxlength");
          textareas[1].value = await translateText(url, descriptionFrom, lang);
          textareas[1].removeAttribute("maxlength");
        }
      }
    }

    setTimeout(() => {
      document.getElementById("translate-spinner").classList.add("hidden");
    }, 1000);

    async function translateText(url, text, lang) {
      let request = `${url}&target=${lang}&q=${encodeURI(text)}`;
      try {
        const response = await fetch(request, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const data = await response.json();
        const translatedText = data.data.translations[0].translatedText;
        const decodedText = decodeHTMLEntities(translatedText);
        return decodedText;
      } catch (error) {
        console.log("There was an error with the translation request: ", error);
        return null;
      }
    }

    function decodeHTMLEntities(text) {
      const element = document.getElementById("decode-div");
      element.innerHTML = text;
      return element.textContent || element.innerText;
    }
    
  }

  change(event) {
    event.preventDefault();
    const lang = event.target.dataset.lang;
    let path = event.path || (event.composedPath && event.composedPath());
    const nav = getElementPath(path, "tag", "nav");
    const checkboxes = nav.querySelectorAll("input");
    this.buttonTarget.dataset.lang = lang;
    checkboxes.forEach((checkbox) => {
      //checkbox.checked = true;
      checkbox.id == `check-${lang}`
        ? checkbox.classList.add("hidden")
        : checkbox.classList.remove("hidden");
    });
  }

  enableTranslation() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.buttonTarget.disabled) {
        this.buttonTarget.disabled = false;
        this.buttonTarget.classList.remove("hidden");
      }
    }, 1000);
  }

  disableTranslation() {
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add("hidden");
  }
  
}
