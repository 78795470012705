// app/javascript/helpers/index.js

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}


/**
 * randomString
 * Generates a new random string according
 * to the length passed by param
 *
 * @param length {number} - Random string length
 */

export function randomString (length) {

  if ( typeof length !== 'number' ) {
    console.error('Length must be an integer');
    return;
  }

  let result = '';
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];

  return result;
}

export function turbo_fetch(url, callback = null){
  fetch(url, {
      headers: {
      Accept: "text/vnd.turbo-stream.html",
      },
  })
  .then(r => r.text())
  .then(html => Turbo.renderStreamMessage(html))
  .then(() => callback)
}

export function getURLParam(param){
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  if (urlParams.has(param)) {
    return urlParams.get(param)
  } else {
    return false
  }
}

export function addURLParam(url, param, value){
  var url = new URL(url)
  var search_params = url.searchParams
  search_params.set(param, value)
  url.search = search_params.toString()
  return url.toString()
}

export function multipleToggle (element, class1, class2){
  element.classList.toggle(class1);
  element.classList.toggle(class2);
}

export function getElementPath(path, elementType, name){
  for (let element of path) {
    switch (elementType){
      case "class":
        if(element.classList && element.classList.contains(name)) return element
        break
      case "tag":
        if(element.tagName == name.toUpperCase()) return element
        break
      default:
        return false
    }
  }
  return false
}

export function getDataValue(path, field){
  let data = ""
  path.forEach(element => {
    if(element.dataset != undefined && element.dataset[`${field}`] != undefined){
      data = element.dataset[`${field}`] 
      return
    } 
  })
  return data
}

export function setDataValue(path, field, value) {
  path.forEach((element) => {
    if (
      element.dataset != undefined &&
      element.dataset[`${field}`] != undefined
    ) {
      element.dataset[`${field}`] = value;
      return;
    }
  });
}

export function popUp(modal) {
  let classes = modal.classList
  if (classes.contains("opacity-0")){
    classes.remove("hidden");
    setTimeout( function() {
      classes.remove("opacity-0");
    }, 1)
  } else {
    classes.add("opacity-0");
    setTimeout( function() {
      classes.add("hidden");
    }, 500)
  }
}

export function hideSelect(select) {
  select.classList.add("hidden");
  select.selectedIndex = 0;
  select.value = "";
}

String.prototype.replaceAt = function(index, replacement) {
  return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}