import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["amount", "script", "button"]

  connect() {

  }

  setAmount() {
    this.buttonTarget.setAttribute("href", `/payments/new?price=${this.amountTarget.value}`);
  }

}