import { Controller } from "stimulus"

let icon
let icon2

export default class extends Controller {

  static targets = ["field", "map", "latitude", "longitude", "community", "province", "municipality", "road", "roadType", "number", "code"]
  static values = { view: String, latitude: String, longitude: String, icon: String, icon2: String }

  connect() {
    
    icon = {
      url: this.iconValue,
      scaledSize: new google.maps.Size(48, 48),
    };

    icon2 = {
      url: this.icon2Value,
      size: new google.maps.Size(80, 80),
      scaledSize: new google.maps.Size(80, 80),
      anchor: new google.maps.Point(40, 40)
    };

    if (typeof (google) != "undefined") {
      switch (this.viewValue) {
        case "app":
          this.initMap()
          break
        case "public":
          this.publicMap()
          break
        default:
          break;
      }
    }
  }

  //
  // Initialize map for property public view
  //

  publicMap() {
    const latlng = {
      lat: parseFloat(this.mapTarget.dataset.lat),
      lng: parseFloat(this.mapTarget.dataset.lng),
    }

    const map = new google.maps.Map(this.mapTarget, {
      center: latlng,
      zoom: 13,
    })
    const geocoder = new google.maps.Geocoder()

    const exact_address = document.getElementById('show_property_check').checked
    const marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: exact_address ? icon : icon2,
      opacity: exact_address ? 1 : 0.7
    })

    google.maps.event.addListener(marker, "click", () => {
      geocoder
        .geocode({ location: latlng })
        .catch((e) => window.alert("Geocoder failed due to: " + e));
    })
    
    //this.marker.setPosition(place.geometry.location);
    //this.marker.setMap(this.map);
  }

  //
  // Initialize map for property admin forms
  //

  initMap() {

    let latlng

    if (this.longitudeValue != "" && this.latitudeValue != "") {
      latlng = {
        lat: parseFloat(this.latitudeValue),
        lng: parseFloat(this.longitudeValue)
      }
    }

    this.map = new google.maps.Map(this.mapTarget, {
      center: (latlng || { lat: 27.97306676109073, lng: -15.58846461772919 }),
      zoom: 10,
      disableDefaultUI: true,
      scaleControl: true,
      zoomControl: true,
    });
    this.geocoder = new google.maps.Geocoder()
    this.markers = []

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, {types: ["geocode"]})
    //this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name', 'place_id'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

    this.marker = new google.maps.Marker({
      map: this.map,
      position: latlng,
      icon: icon,
    });
    if (latlng) this.map.setZoom(15);


    this.map.addListener("click", (click) => {
      this.latitudeTarget.value = click.latLng.lat()
      this.longitudeTarget.value = click.latLng.lng();
      const latlng = {
        lat: parseFloat(click.latLng.lat()),
        lng: parseFloat(click.latLng.lng()),
      };
      this.click(this.geocoder, this.map, latlng)
    });

  }

  //
  // Set location at map through the places automcomplete
  //

  placeChanged() {
    let place = this.autocomplete.getPlace()

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
    }

    if (this.markers.length > 0) {
      this.markers[0].setMap(null);
    }
    this.marker.setPosition(place.geometry.location)
    this.marker.setMap(this.map);
    this.markers.push(this.marker)

    this.geocode(this.geocoder, place.place_id)
  }

  geocode(geocoder, id) {
    geocoder
      .geocode({ placeId: id })
      .then((response) => {
        if (response.results[0]) {
          response.results[0].address_components.forEach((item) => {
            if (item.types[0] == "postal_code") {
              this.codeTarget.value = item.long_name;
            }
          });
          this.latitudeTarget.value = response.results[0].geometry.location.lat();
          this.longitudeTarget.value = response.results[0].geometry.location.lng();
          for (let i = 0; i < response.results.length; i++){
            document.getElementById("geocode_info").innerText = JSON.stringify(response.results[i], null, 2); 
          }
        } else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  }

  //
  // Change location at map through clicks
  //

  click(geocoder, map, input) {
    geocoder
      .geocode({ location: input })
      .then((response) => {
        if (response) {
          this.marker.setPosition(response.results[0].geometry.location);
          this.marker.setMap(map);
          this.fieldTarget.value = response.results[0].formatted_address
          response.results[0].address_components.forEach((item) => {
            switch (item.types[0]) {
              case "street_number":
                this.numberTarget.value = item.long_name;
                break;
              case "route":
                const space = item.long_name.indexOf(" "); 
                if (space === -1) {
                  this.roadTarget.value = item.long_name;
                } else {
                  this.roadTypeTarget.value = item.long_name.substring(0, space)
                  this.roadTarget.value = item.long_name.substring(space + 1);
                }
                break;
              case "locality":
                //this.municipalityTarget.value = item.long_name;
                break;
              case "administrative_area_level_2":
                //this.provinceTarget.value = item.long_name;
                break;
              case "postal_code":
                this.codeTarget.value = item.long_name;
                break;
              default:
                break;
            }
          });
          document.getElementById("geocode_info").innerHTML = ""
          for (let i = 0; i < response.results[0].address_components.length; i++){
            document.getElementById("geocode_info").innerHTML += JSON.stringify(response.results[0].address_components[i], null, 2) + "<br>"; 
          }
        } else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  }
  
  fillAddress() {
    this.fieldTarget.value =
      this.roadTypeTarget.value + " " +
      this.roadTarget.value + ", " +
      this.numberTarget.value + ", " +
      this.municipalityTarget.value + ", " +
      this.provinceTarget.value + ", " +
      this.communityTarget.value;
    this.fieldTarget.value = this.fieldTarget.value.replace(/(,\s)\1+/g, "$1");
    setTimeout(() => {
      this.fieldTarget.dispatchEvent(new Event('change', { bubbles: true }));
      this.fieldTarget.focus();
    }, 100);
  }

  fillFamilyAddress(address) {
    this.provinceTarget.value = address[0]
    this.municipalityTarget.value = address[1];
    this.roadTypeTarget.value = address[2]
    this.roadTarget.value = address[3];
    this.numberTarget.value = address[4];
    //if (address[5]) 
    this.codeTarget.value = address[6];
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }

}
