import { Controller } from "stimulus";
import { setDataValue, getDataValue, getElementPath } from "helpers";

export default class extends Controller {

  static targets = ["price", "form", "submitButton"]
  static values = {hide: Boolean, form: String}

  connect(){
    this.priceTarget.innerHTML = `Cobro diario: ${this.getPrice()}€`
    if (this.formValue == "all" || this.hideValue){
      this.disableReleases()
    }
  }

  updatePrice(){
    let price = parseFloat(this.getPrice())
    this.priceTarget.innerHTML = `Cobro diario: ${price}€`
  }

  getPrice(){
    let price = 0.0
    let checkboxes = this.formTarget.querySelectorAll("input:checked")
    let operation
    let amount
    checkboxes.forEach((checkbox) => {
      operation = checkbox.dataset.operation;
      if (operation != undefined) {
        (operation == 'both') ? amount = parseFloat(checkbox.dataset.price) * 2 : amount = parseFloat(checkbox.dataset.price)
        price = parseFloat((price + amount).toFixed(10));
      }
    })    
    return price
  }

  sendAllReleases(){
    const release = this.getReleases()
    var token = document.querySelector('meta[name="csrf-token"]').content
    fetch('/app/releases', {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify(release),
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then((data)=> {
      Turbo.visit("/app/releases")
    });
  }

  getReleases(){
    let releases = this.getTable()
    let portals = this.formTarget.querySelectorAll('input[type="checkbox"]:checked')
    let property
    let portalRelease

    portals.forEach(function (portal) {
      property = portal.name.split('-')[0]
      portalRelease = portal.name.split('-')[1]
        releases[`${property}`].push(`${portalRelease}`)
    })

    return releases
  }

  getTable(){
    const rows = this.formTarget.querySelectorAll('tr')
    let obj = {};
    let id = []
    const table = Object.create(obj);
    for (var i = 1; i < rows.length; i++) {
      id = rows[i].id.split("-");
      table[`${id[id.length - 1]}`] = []
    }

    return table;
  }

  disableReleases(){
    let hiddens = this.formTarget.querySelectorAll('.propertyHidden input[type=checkbox]')
    hiddens.forEach(function (checkbox) {
      checkbox.disabled = true
    })
  }

  dropdown(event) {
    var path = event.path || (event.composedPath && event.composedPath());
    const family = getElementPath(path, "tag", "tr");
    const properties = document.getElementsByClassName(family.dataset.family);
    family.querySelector("svg").classList.toggle("-rotate-180");

    properties.forEach((property) => {
      property.classList.toggle("hidden")
      this.disableCheckboxes(property);
    })
    //this.disableCheckboxes(family);
  }

  resetFamily(event) {
    let path = event.path || (event.composedPath && event.composedPath());
    const id = getElementPath(path, "tag", "tr").id.substring(0, 16)
    const family = document.getElementById(id)
    this.disableCheckboxes(family, false)
  }

  checkProperties (event) {
    const portal = event.target.id.substring(8)
    let path = event.path || (event.composedPath && event.composedPath());
    const family = getElementPath(path, "tag", "tr");
    const properties = document.getElementsByClassName(family.dataset.family); 
    let subtypes = [];

    // Check one property per type
    properties.forEach((property) => {
      property.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
        if (event.target.checked) {
          if (portal == checkbox.id.substring(11) && !subtypes.includes(property.dataset.subtype)) {
            subtypes.push(property.dataset.subtype);
            checkbox.checked = event.target.checked;
          }
        } else {
          if (portal == checkbox.id.substring(11)) checkbox.checked = false
        }
      })
    })

    this.updatePrice()

  }

  disableCheckboxes(element, disabled = true) {
    element.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
      if( !checkbox.classList.contains("checkbox-disabled") ){
        if (disabled){
          checkbox.toggleAttribute("disabled");
        } else {
          checkbox.checked = false;
        }
      }
    });
  }

  isFamilyReleased(family) {
    const checkbox = family.querySelectorAll("input[type=checkbox]")
    for (let i = 0; i < checkbox.length; i++) {
      if(checkbox[i].checked) return true
    }
    return false
  }

  checkAll(event) {
    const path = event.path || (event.composedPath && event.composedPath());
    const checkboxes = this.formTarget.querySelectorAll("input[type=checkbox]");
    const check = getDataValue(path, "check")
    if (check == "false") {
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) checkbox.click();
      });
      setDataValue(path, "check", "true");
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.click();
      });
      setDataValue(path, "check", "true");
    }
  }

  checkAllPortals(event) {
    let path = event.path || (event.composedPath && event.composedPath());
    const row = getElementPath(path, "tag", "tr");
    const checkboxes = row.querySelectorAll("input[type=checkbox]");
    const check = getDataValue(path, "check");
    if (check == "false") {
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) checkbox.click();
      });
      setDataValue(path, "check", "true");
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.click();
      });
      setDataValue(path, "check", "true");
    }
  }

  checkAllProperties(event) {
    var path = event.path || (event.composedPath && event.composedPath());
    const field = getDataValue(path, "field")
    const checkboxes = document.querySelectorAll('[id$=' + field + ']');
    const check = getDataValue(path, "check")
    if (check == "false") {
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) checkbox.click();
      });
      setDataValue(path, "check", "true");
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.click();
      });
      setDataValue(path, "check", "false");
    }
  }
  

}