import { Controller } from "stimulus";
import { multipleToggle, getURLParam } from "helpers";

export default class extends Controller {
  static targets = ["form", "type", "subtype", "section", "builded", "living", "buildedTag", "livingTag", "pets", "comunity", "comunityField", "referenceField", "referenceDesc",
    "price", "agentSelector", "roadNumber", "draft", "family"];
  static values = { reference: String, agent: String };

  connect() {
    //Set subtypes options, depending on the type selected
    let subtypes = this.formTarget.getElementsByClassName("select_" + this.typeTarget.value);
    for (var i = 0; i < subtypes.length; i++) {
      subtypes[i].classList.remove("hidden");
    }

    //Select the subtype if the property is already created
    if (this.sectionTarget.dataset.subtype && this.sectionTarget.dataset.subtype != "" ) this.subtypeTarget.value = this.sectionTarget.dataset.subtype;

    //Update reference input when the property type is changed
    if (this.hasReferenceFieldTarget) this.checkReference();

    //Do not allow deleting the base of the reference when creating a property familiar
    if (getURLParam("family").length == 7) {
      this.familyTarget.value = getURLParam("family");
      this.referenceFieldTarget.addEventListener("keydown", (e) => {
        if (
          (e.target.value.length > 9 && e.keyCode != 8) ||
          (e.keyCode == 8 && e.target.value.length <= 7)
        ) {
          e.preventDefault();
        }
      });
    }

    //Set the agent of the property when creating a familiar of another property
    if (this.agentValue != "") this.setFamiliarAgent();

    //Set the correct fields inputs, depending on the type and the subtype selected
    this.setForm();

    //If there is an element with class error, scroll on it
    setTimeout(() => {
      if (this.formTarget.getElementsByClassName("error-message")[0]) {
        this.formTarget
          .getElementsByClassName("error-message")[0]
          .scrollIntoView();
      }
    }, 200);
    
  }

  types() {
    let type = this.typeTarget;
    let allSubtypes = this.subtypeTarget;
    let subtypes = this.formTarget.getElementsByClassName("select_" + type.value);

    for (var i = 0; i < allSubtypes.options.length; i++) {
      if (!allSubtypes.options[i].classList.contains("hidden")) allSubtypes.options[i].classList.add("hidden");
    }

    allSubtypes.value = subtypes[0].value;
    for (var i = 0; i < subtypes.length; i++) {
      subtypes[i].classList.remove("hidden");
    }

    if (this.hasReferenceFieldTarget) this.checkReference();
  }

  setForm() {
    let type = this.typeTarget.value;
    let subtype = this.subtypeTarget.value;
    let allFields = this.formTarget.getElementsByClassName("typeField");
    let input;
    let allinputs;

    //Set flag for reset inputs when you change the property type
    let resetInputs = false;
    let currentSubtype = this.sectionTarget.dataset.subtype;
    if (subtype != currentSubtype) resetInputs = true;

    this.setAmenities();

    for (var i = 0; i < allFields.length; i++) {
      //Reset input values when the property type is changed
      if (resetInputs) {
        allinputs = allFields[i].querySelectorAll("input, select");
        for (let input of allinputs) {
          if (input.classList.contains("checkbox")) {
            input.checked = false;
          } else if (input.tagName == "SELECT") {
            input.selectedIndex = 0;
          } else {
            input.value = null;
          }
        }
      }

      //Hide all form elements
      allFields[i].classList.add("sm:hidden");

      //Remove all requires atributes
      if (allFields[i].classList.contains("checkRequire")) {
        input = allFields[i].children[1].getElementsByClassName("checkField")[0];
        input.removeAttribute("required");
      }

      //Show correct fields and add require attributes
      if (
        allFields[i].classList.contains(type) ||
        allFields[i].classList.contains(subtype)
      ) {
        allFields[i].classList.remove("sm:hidden");
        if (allFields[i].classList.contains("checkRequire")) {
          input = allFields[i].children[1].getElementsByClassName("checkField")[0];
          input.setAttribute("required", true);
        }
      }
    }

    //Manage that just one of the area fields (living_area or builded_area) must be required
    if (this.hasLivingTarget) this.setAreaFields();

    //Hide community section
    if (
      type == "Terreno" &&
      (subtype == "Urbanizable" || subtype == "Rústico")
    ) {
      if (document.getElementById("communityTitle")) document.getElementById("communityTitle").classList.add("sm:hidden");
    } else {
      if (document.getElementById("communityTitle")) document.getElementById("communityTitle").classList.remove("sm:hidden");
    }
  }

  //If one of these two fields has a value, the other one is not required
  livingAreaRequired(event) {
    let e = event.target;
    if (e.value == "") {
      this.livingTarget.setAttribute("required", true);
      this.livingTagTarget.innerHTML = "Área habitable (m²) *";
    } else {
      this.livingTarget.removeAttribute("required");
      this.livingTagTarget.innerHTML = "Área habitable (m²)";
    }
  }

  buildedAreaRequired(event) {
    let e = event.target;
    if (e.value == "") {
      this.buildedTarget.setAttribute("required", true);
      this.buildedTagTarget.innerHTML = "Área construida (m²) *";
    } else {
      this.buildedTarget.removeAttribute("required");
      this.buildedTagTarget.innerHTML = "Área construida (m²)";
    }
  }

  setAreaFields() {
    if (this.livingTarget.value != "") {
      this.buildedTagTarget.innerHTML = "Área construida (m²)";
      this.buildedTarget.removeAttribute("required");
    } else {
      this.buildedTagTarget.innerHTML = "Área construida (m²) *";
    }

    if (this.buildedTarget.value != "") {
      this.livingTagTarget.innerHTML = "Área habitable (m²)";
      this.livingTarget.removeAttribute("required");
    } else {
      this.livingTagTarget.innerHTML = "Área habitable (m²) *";
    }
  }

  redirectToPrices() {
    this.priceTarget.checked = true;
    document.getElementById("app_create_property").click();
  }

  saveDraft() {
    this.draftTarget.checked = true;
    this.formTarget.removeAttribute('data-action')
    this.formTarget.submit();
  }

  checkReference() {
    switch (this.typeTarget.value) {
      case "Vivienda":
        this.referenceFieldTarget.setAttribute(
          "pattern",
          "[0-9]{4}[A-Z]{3}[0-3]{1}[0-9]{2}"
        );
        this.referenceDescTarget.innerHTML =
          'Formato: "[0-9]⁴[A-Z]³[0-3]¹[0-9]²"';
        this.changeReference(0);
        break;
      case "Comercial":
        this.referenceFieldTarget.setAttribute(
          "pattern",
          "[0-9]{4}[A-Z]{3}[4-6]{1}[0-9]{2}"
        );
        this.referenceDescTarget.innerHTML =
          'Formato: "[0-9]⁴[A-Z]³[4-6]¹[0-9]²"';
        this.changeReference(4);
        break;
      case "Terreno":
        this.referenceFieldTarget.setAttribute(
          "pattern",
          "[0-9]{4}[A-Z]{3}[7-8]{1}[0-9]{2}"
        );
        this.referenceDescTarget.innerHTML =
          'Formato: "[0-9]⁴[A-Z]³[7-8]¹[0-9]²"';
        this.changeReference(7);
        break;
      case "Otro":
        this.referenceFieldTarget.setAttribute(
          "pattern",
          "[0-9]{4}[A-Z]{3}9[0-9]{2}"
        );
        this.referenceDescTarget.innerHTML = 'Formato: "[0-9]⁴[A-Z]³[9]¹[0-9]²"';
        this.changeReference(9);
        break;
      default:
        this.referenceFieldTarget.setAttribute(
          "pattern",
          "[0-9]{4}[A-Z]{3}[0-9]{3}"
        );
        this.referenceDescTarget.innerHTML = 'Formato: "[0-9]⁴[A-Z]³[0-9]³"';
        break;
    }
  }

  changeReference(x) {
    let reference = this.referenceFieldTarget.value;
    let first = parseInt(reference.charAt(7));
    let flag = false;
    switch (x) {
      case 0:
        if (first > 3) flag = true;
        break;
      case 4:
        if (first < 4 || first > 6) flag = true;
        break;
      case 7:
        if (first < 7 || first > 8) flag = true;
        break;
      case 9:
        if (first != 9) flag = true;
        break;
		}
		reference = reference.replaceAt(7, `${x}`);
		if (flag) {
			fetch(`/app/properties/${reference}/clone`)
        .then((response) => response.json())
        .then((data) => {
          this.referenceFieldTarget.value = data.reference;
				}
			);
		}
  }

  setFamiliarAgent() {
    let that = this;
    var interval = setInterval(function () {
      if (that.hasAgentSelectorTarget) {
        setAgent();
        clearInterval(interval);
      }
    }, 100);

    function setAgent() {
      let options = Array.from(that.agentSelectorTarget.options);
      let optionToSelect = options.find((item) => item.text == that.agentValue);
      optionToSelect.selected = true;
      that.agentSelectorTarget.value = optionToSelect.value;
    }
  }

  removeNumber(event) {
    if (event.target.checked) {
      this.roadNumberTarget.value = "";
      this.roadNumberTarget.setAttribute("disabled", true);
    } else {
      this.roadNumberTarget.removeAttribute("disabled");
    }
  }

  showCounter(event) {
    const target = event.target;
    const counter = target.parentElement.children[1];
    counter.classList.add("bg-gray-200");
    counter.innerHTML = `${target.value.length}/${target.getAttribute(
      "maxlength"
    )}`;
  }

  hideCounter(event) {
    const target = event.target;
    const counter = target.parentElement.children[1];
    target.addEventListener("focusout", () => {
      counter.classList.remove("bg-gray-200");
      counter.innerHTML = "";
      target.removeEventListener("focusout", () => {});
    });
  }

  //Show the correct amenities
  setAmenities() {
    let amenities = document
      .getElementById("amenities_div")
      .getElementsByTagName("label");
    let id;

    //Set the classes to show the correct amenities, depending on the property type
    for (let i = 0; i < amenities.length; i++) {
      id = amenities[i].getElementsByTagName("input")[0].id;
      switch (true) {
        case /ascensor$/i.test(id):
          amenities[i].classList.add(
            "sm:hidden",
            "typeField",
            "Piso",
            "Oficina",
            "Otro"
          );
          break;
        case /trastero$/i.test(id):
          amenities[i].classList.add("sm:hidden", "typeField", "Vivienda");
          break;
        case /utensilios_de_cocina$/i.test(id):
          amenities[i].classList.add("sm:hidden", "typeField", "Piso", "Casa");
          break;
        case /garajeparking$/i.test(id):
          amenities[i].classList.add(
            "sm:hidden",
            "typeField",
            "Vivienda",
            "Comercial",
            "Edificio"
          );
          break;
        case /jardín$/i.test(id):
          amenities[i].classList.add(
            "sm:hidden",
            "typeField",
            "Piso",
            "Casa",
            "Edificio"
          );
          break;
        case /piscina$/i.test(id):
          amenities[i].classList.add(
            "sm:hidden",
            "typeField",
            "Vivienda",
            "Edificio"
          );
          break;
        case /terrazabalcón$/i.test(id):
          amenities[i].classList.add(
            "sm:hidden",
            "typeField",
            "Vivienda",
            "Oficina"
          );
          break;
        case /ropa_de_cama$/i.test(id):
          amenities[i].classList.add("sm:hidden", "typeField", "Piso", "Casa");
          break;
        case /salida_de_humos$/i.test(id):
          amenities[i].classList.add(
            "sm:hidden",
            "typeField",
            "Local",
            "Edificio"
          );
          break;
        case /amenities_wifi$/i.test(id):
          amenities[i].classList.add(
            "sm:hidden",
            "typeField",
            "Vivienda",
            "Oficina",
            "Edificio"
          );
          break;
        default:
          amenities[i].classList.add("sm:hidden", "typeField");
          break;
      }
    }
  }
}
