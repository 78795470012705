import { Controller } from "stimulus";
import { turbo_fetch } from 'helpers';

export default class extends Controller {

    static targets = ["community", "province", "municipality", "town", "nucleus", "road", "code"];
    static values = {address: Array, community: String, model: String, finder: Boolean}

  connect() {
      setTimeout(() => {
        const text = document.getElementById('spinner-text')
        if (text) text.classList.remove('hidden')
      }, 15000);

      setTimeout(() => {
        const spinner = document.getElementById('spinner')
        if (this.communityValue != "") {
          this.setFullAddress();
        } else {
          if(spinner) spinner.classList.add('hidden');
        }
      }, 3000);
    }

    setCommunity(){

      if (this.hasProvinceTarget) this.provinceTarget.value = "";
      if (this.hasMunicipalityTarget) this.municipalityTarget.value = "";

      let selector = this.communityTarget
      let text = selector.options[selector.selectedIndex].getAttribute('data-community')
      if (this.hasFinderValue && this.finderValue) {
        var url = `/app/properties/finder/provinces?CCOM=${text}`;
      } else {
        var url = `/properties/provinces/${this.modelValue}?CCOM=${text}`;
      }
      turbo_fetch(url)
    }

    setProvince(){

      if (this.hasMunicipalityTarget) this.municipalityTarget.value = "";
      
      let selector = this.provinceTarget
      let code = selector.options[selector.selectedIndex].getAttribute('data-province')
      if (this.hasFinderValue && this.finderValue) {
        var url = `/app/properties/finder/municipalities?CPRO=${code}`;
      } else {
        var url = `/properties/municipalities/${this.modelValue}?CPRO=${code}`;
      }
      turbo_fetch(url)
        
    }

    setFinderCommunity(){

        if (this.hasProvinceTarget) {
            this.provinceTarget.value = "";   
            this.provinceTarget.classList.remove("hidden")
        }

        if (this.hasMunicipalityTarget) {
          this.municipalityTarget.value = "";
          this.municipalityTarget.classList.add("hidden");
        } 

      let selector = this.communityTarget
      let text = selector.options[selector.selectedIndex].getAttribute('data-community')
      var url = `/finder/provinces?CCOM=${text}`
      turbo_fetch(url)
    }

    setFinderProvince(){

        if (this.hasMunicipalityTarget) {
          this.municipalityTarget.value = "";
          this.municipalityTarget.classList.remove("hidden");
        }
        
        let selector = this.provinceTarget
        let code = selector.options[selector.selectedIndex].getAttribute('data-province')
        var url = `/finder/municipalities?CPRO=${code}`
        turbo_fetch(url)
        
    }
    
  setFullAddress() {
      const timer = ms => new Promise(res => setTimeout(res, ms));
      timer(3000)
      this.setOptionByText(this.communityTarget, this.addressValue[0])
      if (this.modelValue != "") {
        this.setCommunity();
      } else {
        this.setFinderCommunity();
      }
      let that = this
      timer(2000)
      .then(() => {
        that.setOptionByText(that.provinceTarget, that.addressValue[1]);
      })
      .then(() => {
        if (this.modelValue != "") {
          this.setProvince();
        } else {
          this.setFinderProvince();
        }
        return timer(3000);
      })
      .then(() => {
        that.setOptionByText(that.municipalityTarget, that.addressValue[2]);
      })
      .then(() => {
        let spinner = document.getElementById("spinner");
        if (spinner) spinner.classList.add("hidden");
      })
    }

  setOptionByText(select, option) {
      let executed = false;
      let maxAttempts = 1000;
      let attempts = 0;
      while (!executed && attempts < maxAttempts) {
        try {
          for (var i = 0; i < select.options.length; i++) {
            if (select.options[i].text === option) {
              select.selectedIndex = i;
              break;
            }
          }
          executed = true;
        } catch (error) {
          attempts++;
        }
      }
      if (!executed) {
        alert(
          "Error: No se pudo ejecutar la instrucción después de varios intentos."
        );
      }
    }

    /*setMunicipality(){
        
        if(document.getElementById("townTarget") != null) this.townTarget.value = ""
        if(document.getElementById("nucleusTarget") != null) this.nucleusTarget.value = ""
        if(document.getElementById("codeTarget") != null) this.codeTarget.value = ""
        if(document.getElementById("roadTarget") != null) this.roadTarget.value = ""
        
        let selector = this.municipalityTarget
        let code = selector.options[selector.selectedIndex].getAttribute('data-municipality')
        let province = this.provinceTarget.options[this.provinceTarget.selectedIndex].getAttribute('data-province')
        var url = `/properties/towns?CPRO=${province}&CMUM=${code}`
        turbo_fetch(url)
        
    }
    
    setTown(){
        
        if(document.getElementById("nucleusTarget") != null) this.nucleusTarget.value = ""
        if(document.getElementById("codeTarget") != null) this.codeTarget.value = ""
        if(document.getElementById("roadTarget") != null) this.roadTarget.value = ""
        
        let selector = this.townTarget
        let code = selector.options[selector.selectedIndex].getAttribute('data-town').trim()
        let province = this.provinceTarget.options[this.provinceTarget.selectedIndex].getAttribute('data-province')
        let municipality = this.municipalityTarget.options[this.municipalityTarget.selectedIndex].getAttribute('data-municipality')
        var url = `/properties/nucleus?CPRO=${province}&CMUM=${municipality}&NENTSI50=${encodeURIComponent(code.trim())}`
        turbo_fetch(url)
        
    }
    
    setNucleus(){
        
        if(document.getElementById("codeTarget") != null) this.codeTarget.value = ""
        if(document.getElementById("roadTarget") != null) this.roadTarget.value = ""
        
        let selector = this.nucleusTarget
        let code = selector.options[selector.selectedIndex].getAttribute('data-nucleus')
        let province = this.provinceTarget.options[this.provinceTarget.selectedIndex].getAttribute('data-province')
        let municipality = this.municipalityTarget.options[this.municipalityTarget.selectedIndex].getAttribute('data-municipality')
        var url = `/properties/codes?CPRO=${province}&CMUM=${municipality}&CUN=${code}`
        turbo_fetch(url)
        
    }

    setPostalCode(){

        if(document.getElementById("roadTarget") != null) this.roadTarget.value = ""

        let selector = this.codeTarget
        let code = selector.options[selector.selectedIndex].getAttribute('data-code').trim()
        let province = this.provinceTarget.options[this.provinceTarget.selectedIndex].getAttribute('data-province')
        let municipality = this.municipalityTarget.options[this.municipalityTarget.selectedIndex].getAttribute('data-municipality')
        let nucleus = this.nucleusTarget.options[this.nucleusTarget.selectedIndex].getAttribute('data-nucleus')
        var url = `/properties/roads?CPRO=${province}&CMUM=${municipality}&CUN=${nucleus}&CPOS=${code}`
        turbo_fetch(url)

    }*/


}