import { Controller } from "stimulus";
import Glide from "@glidejs/glide";

export default class extends Controller {

  static targets = ["mainImage"]
  static values = {template: String}

  connect() {
    switch (this.templateValue) {
      case "index":
        new Glide(".gallery", {
          type: "slider",
          perView: 1,
          rewind: false
        }).mount();
        break
      
      case "card":
        const sliders = document.querySelectorAll('.cardGallery')
        const conf = {
          type: "slider",
          perView: 1,
        };
        sliders.forEach(item => {
          new Glide(item, conf).mount()
        })
        break
      
      default:
        break;
    }
  }

  setMainImage(event) {
    this.mainImageTarget.style.backgroundImage = `url('${event.target.dataset.image}')`
    const total = event.target.parentElement.dataset.totalimages
    const image = event.target.dataset.imagenumber
    this.mainImageTarget.children[0].innerHTML = `${image}/${total}`
  }

  slide(event) {
    event.preventDefault()
  }

  fixBody() {
    document.body.classList.toggle("h-screen")
    document.body.classList.toggle("overflow-y-hidden");
  }

}
